<template>
  <div v-if="!isNew && isOnActivity && this.currentActivity.machine_list === undefined && !reseted" class="info-container ">
    <p class="text-reset">{{ $t("activities.multiple.edit.machines") }}</p>
    <RewriteButton :on-click="resetAllMachines"/>
  </div>

  <div v-else>
    <div v-if="!isNew && isOnActivity && showSubcontracted && this.entriesChecked <= 1">
      <span class="text-activity-notes"> {{ $t("components.EditMachines.subcontractedActiviy")}} </span>
      <div class="custom-control custom-switch position-toggle-switch">
        <input type="checkbox" class="custom-control-input" id="customSwitches" v-model="subcontracting" />
        <label class="custom-control-label" for="customSwitches"></label>
      </div>

      <div v-if="subcontracting">
        <!-- Subcontractor select list -->
        <div>
          <b-row>
            <b-col cols="2" class="text-activity-notes shape-subcontracting">
              {{ $t("components.EditMachines.eta")}} :
            </b-col>
            <!-- Sélecteur du sous traitant -->
            <b-col cols="8" class="pr-0 pl-1">
              <Dropdown
                ref="dropdown"
                label="subcontractor"
                :options="subcontractorsClone"
                @input="selectSubcontractor"
                :value="subcontractorSelected"
                :placeholder="$t('components.EditMachines.subcontractorPlaceholder')"
              />
            </b-col>
            <!-- Bouton d'ajout de sous-traitant' -->
            <b-col cols="2" class="pl-3">
              <button
                type="button"
                class="btn btn-light p-0"
                @click="addSubcontractor"
              >
                <img :src="plusIcon" v-b-tooltip.hover :title="$t('components.EditMachines.addSubcontractor')" alt="icon add action"/>
              </button>
            </b-col>
          </b-row>
        </div>
        <!-- Modal d'ajout d'un sous-traitant -->
        <AddSubcontractorModal @saveSubcontractor="saveSubcontractor"/>

        <div>
          <b-row class="my-2">
            <!-- Subcontractor price -->
            <b-col cols="2" class="text-activity-notes shape-subcontracting">
              {{ $t("components.EditMachines.price")}} :
            </b-col>
            <b-col cols="4" class="pl-1">
              <NumericInput
								inputRef="input"
								:value="subcontractorCost"
								@input="i => {subcontractorCost = i}"
								:numberOfDigits="2"
								class="text-infos bg-white"
              />
            </b-col>
            <b-col cols="2 pl-0" class="text-activity-notes shape-subcontracting">
              €
            </b-col>
          </b-row>
        </div>
      </div>
    </div>

    <div v-if="!subcontracting">
      <!-- Machines table -->
      <b-row class="m-0 mb-2 text-infos bg-white" v-for="machine in machinesToPatch" v-bind:key="machine.id" align-v="center" no-gutters>
        <b-col cols="10">{{ machine.machineType_designation + " - " + machine.model }}</b-col>
        <b-col cols="2" class="remove-btn"><button class="btn align-bottom" @click="onDelete(machine.id)">
          <img :src="icon" alt="icon delete action" />
          </button></b-col>
      </b-row>

      <!-- Machines select list -->
      <div v-if="this.errSelectMachine" class="text-activity-notes error-select-machine">
        Sélectionner une machine.
      </div>
      <Dropdown
        ref="dropdown"
        class="shape-custom-select"
        label="machineType_designation_model"
        :placeholder="$t('components.EditMachines.placeholder')"
        :options="this.machinesUpdated"
        @input="selectedMachine => addMachines(selectedMachine.id)"
        :clearSelection="true"
        :clearOnLoad="true"
        :clearOnSelect="true"
      />
    </div>
  </div>
</template>

<script>
import Button from "@/components/commons/HeaderButton.vue";
import delIcon from "@/assets/images/delete.svg";
import ActivityModule from "@/store/modules/activity";
import MachineModule from "@/store/modules/machine";
import { mapGetters } from "vuex";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import Dropdown from "@/components/activity/edit/Dropdown.vue";
import LoadFormPartMixin from "@/mixins/LoadFormPartMixin";
import plusIcon from "@/assets/images/plus_add_supply.svg";
import AddSubcontractorModal from "@/components/activity/modals/AddSubcontractor.vue";
import { EventBus } from "@/eventBus.js";
import RewriteButton from "@/components/commons/RewriteButton.vue";
import NumericInput from "@/components/commons/NumericInput.vue";

export default {
  mixins: [DetailUpdateMixin, LoadFormPartMixin],
  props: {
    useForm: {
      type: String,
      require: true,
    },
    showSubcontracted: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      machinesUpdated: [],
      price: "",
      subcontractorCost: null,
      errSelectMachine: false,
      subcontracting: false,
      subcontractorSelected: null,
      subcontractorsClone: [],
      icon: delIcon,
      machineSelected: "",
      machinesToPatch: [],
      test: '',
      loadComponent: false,
      plusIcon: plusIcon,
      reseted: false
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init(){
      this.loadComponent = false;
      // Récupération de la liste des sous-traitants
      if (this.showSubcontracted){
        await this.$store.dispatch("activity/getSubcontractors");
        this.subcontractorsClone = JSON.parse(JSON.stringify(this.subcontractors));
      }
      // Récupération de la liste des machines
      await this.$store.dispatch("machine/getMachines").then(data => {this.test = data;});

      this.machinesUpdated = this.machine_list.map(obj=> ({ ...obj, machineType_designation_model: obj.machineType_designation + " - " + obj.model }))

      // Si on modifie une activité on initialise le tableau des machines
      // à partir de l'activité actuel
      if (["activity.edit", "tour.edit", "tour.duplicate"].includes(this.$route.name)) {
        if(this.currentEditedEntry.machine_list) {
          this.currentEditedEntry.machine_list.map(machine => {
          this.machinesToPatch.push(machine);
        });
        } else if (this.currentEntry.machine_list) {
          this.currentEntry.machine_list.map(machine => {
            this.machinesToPatch.push(machine);
          });
        }

        // Initialisation du sous-traitant
        if (this.showSubcontracted && this.entriesChecked <= 1) {
          let subcontractorName = this.currentEditedEntry.subcontractor || this.currentEntry.subcontractor || null;
          this.subcontractorSelected = {subcontractor: subcontractorName};
          this.subcontractorCost = this.currentEditedEntry.subcontractorCost || this.currentEntry.subcontractorCost || null;
          if(subcontractorName || this.subcontractorCost) {
            this.subcontracting = true;
          }
        }

      }
      this.loadComponent = true;
    },
    /**
     * Description: Renvoie le machinesToPatch.
     * @return {Array}
     */
    getMachinesToPatch() {
      return this.machinesToPatch.map(el => el.id);
    },

    /**
     * Delete machine into machinesToPatch.
     */
    onDelete(idMachine) {
      this.machinesToPatch = this.machinesToPatch.filter(el => el.id != idMachine);
      if(this.isOnActivity) this.changeServiceForm();
    },

    /**
     * Description: Renvoie le prix de l'activité sous-traité.
     * @return {Number}
     */
    getPriceToPatch() {
      return this.price;
    },

    /**
     * Add machine into the array 'machinesToPatch'.
     */
    addMachines(idMachine) {
      if (idMachine != "") {
        let machine = this.machine_list.find(el => el.id == idMachine)

        if (machine && !this.checkIfMachineWasAdded(machine.id)){
          this.machinesToPatch.push(machine);
          if(this.isOnActivity) this.changeServiceForm()
        } 
      }
    },

    /**
     * Appel les services en fonction du changement des machines.
     */
    changeServiceForm(){
      this.$store.dispatch("getServices", {
          activityType: this.currentEditedEntry?.activityType,
          machines: this.getMachinesToPatch(),
        });
    },

    /**
     * Description: Renvoie true si une machine a déjà été ajouté sinon false.
     * @param {number}      id L'id de la machine.
     * @return {boolean}
     */
    checkIfMachineWasAdded(id) {
      let response = this.machinesToPatch.filter(function(elem) {
        if (elem.id == id) return elem;
      });

      if (response.length > 0) return true;
      return false;
    },

    /**
     * Sélection d'un sous-traitant
     */
    selectSubcontractor(value) {
      if (value && value.subcontractor)
        this.subcontractorSelected = value;
      else
        this.subcontractorSelected = null;
    },

    /**
     * Ouvre la modal d'édition des sous-traitants
     */
    addSubcontractor() {
      EventBus.$emit("showSubcontractorModal");
    },
    /**
     * Enregistre un nouveau sous-traitant
     */
    saveSubcontractor(name) {
      let subcontractorObject = {subcontractor: name}
      this.subcontractorsClone.push(subcontractorObject);
      this.subcontractorSelected = subcontractorObject;
    },

    /**
     * Description: Confirme le chargement des données de ce composant.
     * @return {Boolean} Component loaded.
     */
    validateForm: function() {
      this.isValid = true;
      return this.loadComponent;
    },

    resetAllMachines(){
      this.reseted = true
      this.$store.dispatch(this.storeEditAction, { machines: [] });
    }
  },
  components: {
    Button,
    Dropdown,
    AddSubcontractorModal,
    RewriteButton,
    NumericInput
  },
  beforeCreate() {
    const store = this.$store;
    if (store && store.state) {
      if (!store.state["activity"]) {
        store.registerModule("activity", ActivityModule);
      }

      if (!store.state["machine"]) {
        store.registerModule("machine", MachineModule);
      }
    }
  },
  computed: {
    ...mapGetters({
      currentActivity: "activity/currentEntry",
      machine_list: "machine/entries",
      subcontractors: "activity/subcontractors",
      entriesChecked: "activity/entriesChecked",
    }),
    getMachines() {
      return this.machine_list;
    },
    /**
     * Renvoie un array pris de this.machines + surchargé avec un attribut
     * machineType_designation_model qui va être util dans le DropDown
     */
    getMachinesUpdated()
    {
      this.machinesUpdated = this.machine_list.map(obj=> ({ ...obj, machineType_designation_model: obj.machineType_designation + " - " + obj.model }))
      return this.machinesUpdated;
    },
    isOnActivity() {
      return this.$route.name.includes("activity");
    },
    isNew(){
      return this.$route.name.includes("new")
    },
  },
  watch: {
    machinesToPatch: {
      handler: async function() {
        await this.$store.dispatch(this.storeEditAction, {machines: this.getMachinesToPatch()});
      }
    },
    subcontractorSelected: {
      handler: async function() {
        await this.$store.dispatch(this.storeEditAction, {subcontractor: this.subcontractorSelected ? this.subcontractorSelected.subcontractor : null});
      }
    },
    subcontractorCost: {
      handler: async function() {
        await this.$store.dispatch(this.storeEditAction, {subcontractorCost: this.subcontractorCost ? this.subcontractorCost : null});
      }
    },
    subcontracting: {
      handler: async function() {
        if (this.subcontracting){
          this.machinesToPatch = [];
        } else {
          this.subcontractorSelected = null;
          this.subcontractorCost = null;
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
/**
 * Toggle switch align right.
 */
.position-toggle-switch {
  text-align: center;
  position: relative;
  bottom: 23px;
}

table {
  border-collapse: separate;
  border-spacing: 0px 3px;
  margin-bottom: 4px;
}

/**
 * Config button add machine.
 */
.config-button-addmachine {
  margin: 5px 0px 0px 0px;
  width: 200px;
}

.container{
  text-align: center;
}

button {
  font-size: 0.8em;
  font-weight: bold;

  img {
    width: 1.5em;
  }
}

/**
 * Shape subcontracting.
 */
.shape-subcontracting {
  margin: 5px 0px 0px 0px;
}

/**
 * Parameter error machine.
 */
.error-select-machine {
  // text-align: center;
  color: $danger-color;
}

.text-reset {
    font-size: 14px;
    padding: 5px;
    color: #222e50;
    margin: 5px;
}

.info-container {
  display: flex;
  align-items: center; /* Centre verticalement les éléments */
  justify-content: space-between; /* Place le texte à gauche et le bouton à droite */
}
</style>
