<template>
<!-- Modal d'ajout d'un sous-traitant -->
<div>
  <b-modal ref="subcontractorModal" header-bg-variant="success" centered>

		<!-- header -->
    <template v-slot:modal-header>
      <div>
        <b-form-row>
          <b-col class="news-title">
            {{ $t("components.EditMachines.newSubcontractor") }}
          </b-col>
        </b-form-row>
      </div>
    </template>

		<!-- body -->
		<b-row align-v="center" class="my-2">
      <b-col cols="2" class="pl-5">
        {{ $t("components.EditMachines.name") }}
      </b-col>
      <b-col cols="10" class="pr-4">
        <b-input type="text" v-model="subcontractorName"></b-input>
      </b-col>
    </b-row>

		<!-- footer -->
		<template v-slot:modal-footer>
      <div class="w-100 d-flex justify-content-end align-items-center">
        <b-button v-if="subcontractorName" class="btn btn-success mr-2" @click="onSave">
					{{ $t('save') }}
				</b-button>
        <b-button class="mr-2" @click="hideModal">
          {{ $t('cancel') }}
        </b-button>
			</div>
    </template>

  </b-modal>
</div>
</template>

<script>
import { EventBus } from "@/eventBus.js";

export default {
  data: function () {
    return {
      subcontractorName: "",
    };
  },
	created() {
    EventBus.$on("showSubcontractorModal", this.showModal);
  },
  beforeDestroy(){
    EventBus.$off("showSubcontractorModal", this.showModal);
  },
  methods: {
		showModal() {
      this.$refs.subcontractorModal.show();
    },
		hideModal() {
      this.$refs.subcontractorModal.hide();
    },
		onSave() {
			this.$emit("saveSubcontractor", this.subcontractorName);
			this.hideModal();
		}
  },
};
</script>

<style lang="scss" scoped>
.news-title {
  font-weight: bold;
}
</style>